<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <InfoBlock />
      </div>
      <!--      <div class="col-lg-6 col-md-6 col-12">-->
      <!--        <AddImgBlocks/>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  components: {
    InfoBlock: () => ({
      component: import("@/components/add/expeditor/info"),
    }),
    // AddImgBlocks: () => ({
    //   component: import('@/components/add/driver/addImg')
    // })
  },
  data() {
    return {
      driverData: null,
    };
  },
};
</script>

<style scoped></style>
